import { React, useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Event = ({ name, description, location, datetime, time, image, link }) => {
	return (
		<div className="col col-md-6 col-xl-4 mb-3">
			<Card className="h-100">
				<Card.Body className="d-flex flex-column justify-content-between">
					<div className="card-main">
						<EventImage image={`${process.env.PUBLIC_URL}/${image}`} />
						<Card.Title className="mt-2">{name}</Card.Title>
						<Card.Text>{description}</Card.Text>
						<Card.Text>{location}</Card.Text>
						<Card.Text>
							<DateTime datetime={datetime} />
						</Card.Text>
					</div>
					<EventButton link={link} />
				</Card.Body>
			</Card>
		</div>
	);
};

const DateTime = ({ datetime }) => {
	var options = { weekday: "short", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };
	const { i18n } = useTranslation();
	return new Date(datetime).toLocaleDateString(i18n.language, options);
};

const EventImage = ({ image }) => {
	console.info("image", image);
	if (image) {
		return <Card.Img src={image} alt="image" />;
	} else {
		return null;
	}
};

const EventButton = ({ link }) => {
	const { t } = useTranslation();
	if (link) {
		return (
			<Button href={link} target="_blank" className="mt-3">
				{t("comeSeeMe.learnMoreButton")}
			</Button>
		);
	} else {
		return null;
	}
};

const EventList = ({ events }) => {
	const { t } = useTranslation();
	if (events.length === 0) {
		return <p className="text-white">{t("comeSeeMe.noEventMessage")}</p>;
	} else {
		return events.map(event => <Event key={event.datetime} {...event} />);
	}
};

const Events = ({ events }) => {
	const { t } = useTranslation();
	const eventsWithDate = events.map(event => {
		const datetime = new Date(event.datetime);
		return { ...event, datetime };
	});
	const pastEvents = eventsWithDate.filter(event => event.datetime < new Date());
	const futureEvents = eventsWithDate.filter(event => event.datetime >= new Date());

	return (
		<>
			<div className="col-12">
				<h2>{t("comeSeeMe.futureEvents")}</h2>
			</div>
			<EventList events={futureEvents} />
			<div className="col-12">
				<h2>{t("comeSeeMe.pastEvents")}</h2>
			</div>
			<EventList events={pastEvents} />
		</>
	);
};

const ComeSeeMe = () => {
	const [events, setEvents] = useState([]);
	const { t } = useTranslation();
	useEffect(() => {
		fetch(`${process.env.PUBLIC_URL}/data/events.json`)
			.then(response => response.json())
			.then(data => setEvents(data))
			.catch(error => console.error("Error", error));
	}, []);
	return (
		<div>
			<h1 className="color-white mt-3">{t("comeSeeMe.pageTitle")}</h1>
			<div className="row">
				<Events events={events} />
			</div>
		</div>
	);
};

export default ComeSeeMe;
